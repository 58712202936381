.parent-dimension {
  font-size: 1.1em;
  font-weight: bold;
  color: steelblue;
}

.zone-select-template {
  display: none;
  max-width: 600px;
}

.dimensions-table th {
  text-align: center;
}

.dimensions-table td {
  width: 120px;
  max-width: 120px;
}

.dimensions-table .score-cell {
  width: 25px;
  max-width: 25px;
  text-align: center;
}

.dimensions-table .zone-cell {
  width: 150px;
  max-width: 150px;
}

.dimensions-table .importance-cell {
  width: 40px;
  max-width: 40px;
  text-align: center;
}

.dimensions-table .zscore-cell {
  width: 65px;
  max-width: 65px;
  text-align: center;
}

.dimensions-table .impzscore-cell {
  width: 65px;
  max-width: 65px;
  text-align: center;
}

.bvi-view .bvi-container {
  max-width: 500px;
  margin: auto;
}

.bvi-legend {
  height: 2.5rem;
}

.bvi-legend strong {
  margin-left: 5px;
}
