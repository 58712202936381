.job-report table {
  margin-bottom: 4rem;
}

.job-report h1 {
  text-align: center;
}

.job-report th {
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;
  background-color: #f9f9f9;
}

.job-report td {
  border: 1px solid #ddd;
}

.job-report .resources {
  max-height: 10rem;
  overflow-y: auto;
}

.job-report #resources {
  min-width: 14rem;
}

.job-report #cycle-name {
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
}

.job-report #cycle-name i {
  margin-left: 0.4em;
}

.job-report .category td {
  font-weight: bold;
  text-align: center;
  background-color: #e4e4e4;
}

.job-report .category td,
.job-report .competency td {
  font-size: 0.85rem;
}

.job-report .score,
.job-report .no-score {
  min-width: 5.75rem;
  text-align: center;
}

.job-report #overall {
  max-width: 5rem;
}

.job-report th.thicker-border {
  border-right: 1px solid rgb(221 221 221);
}

.job-report td.improvement {
  text-align: center;
  border-left: hidden;
}

.job-report .overall-average td {
  font-size: 0.85rem;
  font-weight: bold;
}

.job-report .overall-average td.improvement {
  font-size: 0.85rem;
}
