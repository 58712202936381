.skillbuilder-home table tr td {
  vertical-align: top;
  border: 1px solid #ccc;
}

.skillbuilder-home .note-cell {
  text-align: right;
}

.skillbuilder-home .note-cell textarea {
  min-width: 12em;
  max-width: 17em;
  min-height: 5em;
  vertical-align: top;
}
