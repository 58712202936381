body.testprep-wrapper {
  font-size: 1rem;
  background-color: #e7e7e7 !important;
}

.testprep-wrapper .button,
.testprep-wrapper .button.primary {
  background-color: #68b6ff;
}

.testprep-wrapper .button {
  color: #e7e7e7 !important;
  background-color: rgb(135 195 55);
  border-radius: 20px;
  transition: background-color 0.25s ease-out, background-color 0.25s ease-out;
}

.testprep-wrapper .button:hover,
.testprep-wrapper .button.primary:hover,
.testprep-wrapper .button:focus {
  background-color: #44a5ff;
}

.testprep-wrapper .small-logo {
  font-size: 30px;
  font-weight: 700;
}

.testprep-wrapper .small-logo-left {
  color: #68b6ff;
}

.testprep-wrapper .radius {
  border-radius: 3px;
}

.testprep-wrapper .float-right {
  float: right;
}

.testprep-wrapper .notification-wrapper {
  padding-bottom: 13px;
}

.testprep-wrapper .callout p {
  padding-right: 15px;
}

.testprep-wrapper .callout.error {
  color: #0a0a0a;
  background-color: #f7e4e1;
}

.testprep-wrapper .callout.info {
  color: #0a0a0a;
  background-color: #d7ecfa;
}

.testprep-wrapper .content-body {
  min-height: 80vh;
  padding-top: 35px;
  padding-bottom: 100px;
}

.testprep-wrapper .footer {
  height: 20vh;
  padding-top: 25px;
  padding-left: 20px;
  color: rgb(255 255 255);
  background-color: rgb(30 30 30);
}

.testprep-wrapper .top-bar,
.testprep-wrapper .top-bar ul {
  background-color: rgb(30 30 30);
}

.testprep-wrapper .button:hover,
.testprep-wrapper .button:focus {
  background-color: rgb(85 135 55);
}

.testprep-wrapper .button.expand {
  width: 100%;
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
}

.testprep-wrapper .section-primary {
  font-weight: bold;
}

.testprep-wrapper .section-secondary {
  font-weight: bold;
  color: rgb(85 135 55);
  text-align: center;
}

.testprep-wrapper p.learn-bullet-point {
  padding-left: 40px;
  background: url("../images/dot.jpg") left center no-repeat;
}

.testprep-wrapper .panel {
  padding: 3em 1em;
  margin: 1em 0;
  background: linear-gradient(to bottom, rgb(239 239 239 / 100%) 0%, rgb(220 220 220 / 100%) 100%);
  border: 0 inset;
  border-radius: 15px;
  box-shadow: inset 1px 1px 3px 0 rgb(0 0 0 / 30%);
}

.testprep-wrapper .panel.resources {
  padding-top: 0.5em;
  background: rgb(239 239 239 / 100%);
}

.testprep-wrapper .panel.results {
  padding: 1em 3em;
}

.testprep-wrapper .panel.green-background {
  background: linear-gradient(to bottom, #77a05e 0%, #608f41 100%);
}

.testprep-wrapper .home-navigation {
  float: right;
  padding-top: 1em;
  margin-top: 40px;
  font-size: 1.125rem;
}

.testprep-wrapper .accordion-item a {
  background-color: #e5e5e5;
}

.testprep-wrapper .home-navigation a {
  padding: 10px;
  color: #555;
  transition: color 0.25s ease-out;
}

.testprep-wrapper .home-navigation a:hover {
  color: #abd762;
}

.testprep-wrapper .home-navigation a:focus {
  background: none;
}

.testprep-wrapper .accordion-item.is-active a {
  font-size: 1em;
  color: #77a05e;
  background-color: inherit;
}

.testprep-wrapper .home-navigation .is-active a {
  color: white;
  background-color: #77a05e;
  border-radius: 15px;
  transition: background-color 0.25s ease-out, background-color 0.25s ease-out;
}

.testprep-wrapper .home-navigation .is-active a:hover,
.testprep-wrapper .home-navigation .is-active a:focus {
  color: #fefefe;
  background-color: #abd762;
  border-radius: 15px;
}

.testprep-wrapper .home-navigation .standout-link {
  font-weight: bold;
  color: #77a05e;
}

.testprep-wrapper .content {
  padding-bottom: 0;
  color: #808587;
  background-color: #e7e7e7;
}

.testprep-wrapper .results-title {
  text-align: center;
}

.testprep-wrapper .accordion {
  background-color: #ddd;
  border-bottom-width: 0;
  border-radius: 15px;
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
}

.testprep-wrapper .accordion-title {
  font-weight: bold;
  color: #808587;
  background-color: inherit;
  transition: color 0.1s ease-out, font-size 0.25s ease-out;
}

.testprep-wrapper .accordion-title:hover,
.testprep-wrapper .accordion-title:focus {
  font-size: 1em;
  color: #77a05e;
  background-color: inherit;
}

.testprep-wrapper .accordion-item {
  border-width: 0;
}

.testprep-wrapper .accordion-content {
  background-color: #e5e5e5;
  border-top: none;
  border-bottom-width: 0;
}

.testprep-wrapper .accordion-item:first-child > :first-child {
  border-radius: 15px 15px 0 0;
}

.testprep-wrapper .accordion-item:last-child:not(.is-active) > .accordion-title {
  border-radius: 0 0 15px 15px;
}

.testprep-wrapper .testprep-link {
  color: #555;
}

.testprep-wrapper .testprep-link:hover,
.testprep-wrapper .testprep-link:focus {
  color: #555;
  text-decoration-line: underline;
}
