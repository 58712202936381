#select-viewmode {
  display: inline-block;
  margin-right: 10px;
}

#actions-bar {
  float: right;
}

.action-gear {
  display: inline-block;
}

.action-gear a {
  color: #686868;
}

.comment-submit-button {
  float: right;
  margin-top: 10px;
}

.cycle-datatable th {
  text-align: center;
}

.cycle-datatable tr {
  cursor: pointer;
}

tr.hired,
tr.hired:nth-child(even) {
  background-color: #9dd49d;
}

tr.not-recommended,
tr.not-recommended:nth-child(even) {
  background-color: #dfdfdf;
}

.detail-view #cycle-actions {
  float: right;
}

.detail-view #top-actions {
  display: inline;
}

li.search-filtered {
  display: none;
}

.detail-view .side-open {
  transform: translate3d(15rem, 0, 0);
}

.detail-view #inner-close-canvas {
  box-shadow: none;
}

.content {
  min-height: 30rem;
}

.detail-view .comments-link {
  margin-left: 5px;
  color: gray;
}

@keyframes highlight {
  0% {
    background-color: rgba(rgb(173 202 230 / 0%));
  }

  50% {
    background-color: rgba(rgb(173 202 230 / 16%));
  }

  100% {
    background-color: rgb(173 202 230 / 35%);
  }
}

.detail-view .highlight-row {
  background-color: rgb(173 202 230 / 35%) !important;
  animation-name: highlight;
  animation-duration: 1s;
}

.detail-view #action-list {
  padding: 0;
  margin: auto;
  list-style-type: none;
}

.detail-view #action-list li {
  display: inline;
  margin-left: 8px;
}

.detail-view #action-list li a {
  color: slategray;
}

.detail-view #action-list li a:hover {
  color: lightblue;
}

.detail-view #action-list li:last-child {
  float: right;
}

#switch-view {
  font-size: 0.8rem;
  font-weight: bold;
  color: #12b886;
  background-color: transparent;
  border: 3px solid #12b886;
}

#switch-view:hover {
  color: white;
  background-color: rgb(67 172 106);
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected { /* stylelint-disable-line selector-class-pattern */
  background-color: #2DB1FF;
}

ul#integrations-list li div.panel {
  text-align: center;
  border-radius: 20px;
}

ul#integrations-list li div.panel.active {
  background-color: rgb(67 172 106 / 50%);
}


ul#integrations-list li div.panel .integration-title {
  margin: auto;

}

.detail-view .data-view {
  padding-bottom: 120px
}

.detail-view #update-cycle-info {
  display: none;
  max-width: 20em;
  min-height: 4em;
  padding-top: 1em;
  padding-left: 1.2em;
  margin-right: 1em;
  margin-bottom: 2em;
  font-size: 0.8rem;
  font-weight: bold;
  color: #12b886;
  background: #FFF;
  border: 3px solid #12b886;
  border-radius: 3px;
}

.detail-view .status-cell-value {
  padding: 25px 8px;
}

.detail-view .status-cell-editable:hover {
  padding: 25px 0 25px 5px;
  font-weight: bolder;
  cursor: pointer;
}
