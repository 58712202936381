.top-callus {
  font-size: 18px;
}

.top-callus a {
  color: #2DB1FF;
}

.top-callus a:hover {
  color: #008EE2;
}

.thanks-wrapper {
  margin-top: 50px;
}

.thanks-wrapper .panel {
  background-color: white;
}
