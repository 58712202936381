div#applicant-card {
  position: fixed;
  top: 3.5rem;
  right: 1rem;
  z-index: 100;
  opacity: 1;
  transition: right 300ms, opacity 500ms;
}

div#applicant-card.closed {
  right: -32rem;
  opacity: 0;
  transition: right 300ms, opacity 350ms;
}

div#applicant-card[data-simple] {
  position: relative;
  top: 0;
  right: 0;
  z-index: 88;
  display: flex;
}

div#applicant-card[data-simple] div#card  {
  flex: 1;
}

div#applicant-card div.baseline-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
