.c-dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.c-dropdown-trigger {
  width: auto;
  cursor: pointer;
}

.c-dropdown-wrapper .c-dropdown-trigger > * {
  color: #5a5a5a;
}

.c-dropdown-wrapper .c-dropdown-trigger:hover > * {
  color: black;
}

.c-dropdown {
  position: absolute;
  z-index: 200;
  width: auto;
  max-width: 400px;
  background-color: rgb(248 248 248);
  border: 1px solid lightgray;
  opacity: 0;
}

.c-dropdown[data-down] {
  top: 110%;
}

.c-dropdown[data-up] {
  bottom: 110%;
}

.c-dropdown[data-down] .c-dropdown-wrapper.sub {
  top: 0;
}

.c-dropdown[data-up] .c-dropdown-wrapper.sub {
  bottom: 0;
}

.c-dropdown[data-left] {
  left: -9999px;
  transition: left 0ms linear 800ms, opacity 200ms ease-out 600ms;
}

.c-dropdown[data-right] {
  right: -9999px;
  transition: right 0ms linear 800ms, opacity 200ms ease-out 600ms;
}

.c-dropdown[data-center] {
  left: -9999px;
  border-radius: 5px;
  transition: left 0ms linear 800ms, opacity 200ms ease-out 600ms;
}

.c-dropdown:hover,
.c-dropdown-wrapper:hover > .c-dropdown {
  opacity: 1;
  transition: opacity 0ms;
}

.c-dropdown[data-left]:hover,
.c-dropdown-wrapper:hover > .c-dropdown[data-left] {
  left: 10px;
  transition: left 0ms linear 25ms;
}

.c-dropdown[data-right]:hover,
.c-dropdown-wrapper:hover > .c-dropdown[data-right] {
  right: 10px;
  z-index: 250;
  transition: right 0ms linear 25ms;
}

.c-dropdown[data-center]:hover,
.c-dropdown-wrapper:hover > .c-dropdown[data-center] {
  left: -50%;
  z-index: 250;
  transition: left 0ms linear 25ms;
}

.c-dropdown[data-down] .c-dropdown-wrapper.sub .c-dropdown {
  top: 0;
}

.c-dropdown[data-up] .c-dropdown-wrapper.sub .c-dropdown {
  bottom: 0;
}

.sub > .c-dropdown[data-left]:hover,
.c-dropdown-wrapper.sub:hover > .c-dropdown[data-left] {
  left: 100%;
  transition: left 0ms;
}

.sub > .c-dropdown[data-right]:hover,
.c-dropdown-wrapper.sub:hover > .c-dropdown[data-right] {
  right: 100%;
  transition: right 0ms;
}

.c-dropdown-content {
  width: auto;
  min-width: 150px;
  max-width: 400px;
}

div.c-dropdown-content {
  width: max-content;
  padding: 0.5rem;
  cursor: default;
}


/* Tooltips */

.c-dropdown[data-left][data-tip],
.c-dropdown[data-center][data-tip] {
  transition: left 0ms linear 200ms, opacity 200ms ease-out !important;
}

.c-dropdown[data-left][data-tip]:hover,
.c-dropdown-wrapper:hover > .c-dropdown[data-left][data-tip] {
  transition: left 0ms linear 300ms !important;
}

.c-dropdown[data-right][data-tip] {
  transition: right 0ms linear 200ms, opacity 200ms ease-out !important;
}

.c-dropdown[data-right][data-tip]:hover,
.c-dropdown-wrapper:hover > .c-dropdown[data-right][data-tip] {
  transition: right 0ms linear 300ms !important;
}

/* This css is specifically for list dropdowns, not content */

ul.c-dropdown-content {
  margin: 0;
  list-style: none;
}

ul.c-dropdown-content > li {
  display: flex;
}

ul.c-dropdown-content > li:not(:last-of-type) {
  border-bottom: 1px solid lightgray;
}

ul.c-dropdown-content > li > a.highlighted {
  background-color: lightyellow;
}

ul.c-dropdown-content > li > a.highlighted:hover {
  background-color: #ffffc0 !important;
}

ul.c-dropdown-content > li > a,
ul.c-dropdown-content > li > .c-dropdown-wrapper > a.c-dropdown-trigger {
  flex-grow: 1;
  align-self: flex-start;
  width: 100%;
  padding: 0.5rem 0.25rem;
  font-weight: bold;
  color: #5a5a5a;
}

ul.c-dropdown-content > li > a:hover,
ul.c-dropdown-content > li > .c-dropdown-wrapper > a.c-dropdown-trigger:hover {
  color: black;
  background-color: hsl(0deg 0% 0% / 10%);
  border-color: #888;
}
