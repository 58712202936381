#detail-view {
  max-width: 100%;
}

#detail-view .data-view table {
  overflow: auto;
}

#detail-view .data-view table th,
#detail-view .data-view table td {
  border: none;
}

#detail-view .data-view table tbody td[data-column] {
  height: 5rem;
  min-height: 5rem;
}

#detail-view.condensed .data-view table thead tr {
  height: 5rem;
  min-height: 5rem;
}

#detail-view .data-view table th {
  background-color: #d6dce4;
}

#detail-view .data-view table th[data-phase="1"],
#detail-view .data-view table th[data-phase="3"],
#detail-view .data-view table th[data-phase="5"],
#detail-view .data-view table th[data-phase="7"],
#detail-view .data-view table th[data-phase="9"] {
  background-color: #c5d1e2;
}

#detail-view .data-view table th[data-column*="Score"] a.c-dropdown-trigger {
  color: #222;
  text-decoration: dotted underline;
}

#detail-view .data-view table th[data-column*="Score"]:hover a[data-module-header] {
  text-decoration: solid underline;
}

#detail-view .data-view table tbody tr.selected {
  background-color: rgb(208 231 0 / 35%) !important;
  transition: backgorund-color 250ms;
}

#detail-view .data-view table tbody tr:hover {
  cursor: pointer;
  background-color: rgb(76 153 225 / 25.3%) !important;
  transition: background-color 250ms;
}

#detail-view .data-view table tbody tr.hired:hover {
  background-color: rgb(75 188 75 / 50%) !important;
  transition: background-color 250ms;
}

#detail-view .data-view table tbody tr.highlight-row:hover {
  background-color: rgb(76 152 225 / 35%) !important;
  transition: background-color 250ms;
}

#detail-view .data-view table tbody tr.selected:hover {
  background-color: rgb(208 231 0 / 50%) !important;
  transition: backgorund-color 250ms;
}

/* Custom Column CSS */
#detail-view .data-view table tbody td[data-column="row-selector"] {
  width: 40px;
  text-align: center;
}

#detail-view .data-view table [data-column="row-selector"] input {
  margin: auto;
  transform: scale(1.25);
}

#detail-view .data-view table tbody td[data-column="appId"] {
  width: 4rem;
  text-align: center;
}

#detail-view .data-view table tbody td[data-column="a.lastName"] {
  width: auto;
  text-align: left;
  white-space: nowrap;
}

#detail-view .data-view table tbody td[data-column="a.lastName"] .location {
  width: auto;
  font-size: 90%;
  color: #4c4c4c;
}

#detail-view .data-view table tbody td[data-column="a.submittedAt"] {
  text-align: center;
}

#detail-view .data-view table td[data-column*="Score"] {
  width: 3rem;
  max-width: 3rem;
}

#detail-view .data-view table tbody td[data-column*="Score"] a:hover {
  width: 5%;
  max-width: 5%;
  padding: 1rem;
  font-size: 145%;
  font-weight: bold;
  color: #5e5e5e;
  text-decoration: underline dotted;
  background-color: #69696917;
  border-radius: 15px;
  transition: font-size 100ms;
}

#detail-view .data-view table tbody td[data-column="st.value"] {
  width: 10%;
  max-width: 10%;
  overflow: hidden;
  text-align: center;
  text-overflow: clip;
  white-space: nowrap;
}

#detail-view .data-view table tbody td[data-column="percentileScore32"] {
  font-size: 105%;
  font-weight: bold;
}

#detail-view .data-view table tbody td[data-column="discussion"] {
  width: auto;
  min-width: 8rem;
  max-width: 10rem;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container {
  position: relative;
  height: 4rem;
  min-height: 2rem;
  max-height: 4rem;
  padding: 0.3rem;
  overflow: hidden;
  border: solid 1px transparent;
  border-radius: 3px;
  transition: border-color 200ms ease-out;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container:hover {
  padding: 0.25rem 0.5rem;
  overflow: auto;
  font-size: 90%;
  border-color: gray;
  transition: border-color 200ms ease-out;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container ul:empty {
  height: 0;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container:hover ul:empty {
  height: 100%;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container:hover ul:empty::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  font-size: 120%;
  font-weight: bold;
  text-align: center;
  content: 'Open Notes'; /* [discussion-to-notes] */
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container:hover ul:not(:empty)::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: inherit;
  padding: 0.2rem;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
  content: 'Open Notes'; /* [discussion-to-notes] */
  background: transparent;
  border: 1px solid gray;
  border-radius: 3px;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container ul.pinned-comments-list {
  width: auto;
  list-style: none;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container ul.pinned-comments-list li.pinned-comment-item p {
  width: inherit;
  overflow: hidden;
  font-size: 8pt;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container:hover ul.pinned-comments-list li.pinned-comment-item p {
  margin-top: 0.25rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-comments-container ul.pinned-comments-list li.pinned-comment-item p:hover {
  text-decoration: underline dotted gray;
  white-space: normal;
}

#detail-view .data-view table tbody td[data-column="discussion"] div.cell-tag-container {
  width: auto;
  text-align: center;
}

#detail-view .data-view table tbody td[data-column="discussion"] span.courtesy-letter:hover {
  /* Remove this when we make custom flags */
  color: #2DB1FF;
  cursor: help;
}

#detail-view.condensed {
  max-width: 40vw;
  transition: max-width 350ms;
}

#detail-view.condensed .data-view table [data-expanded-only] {
  display: none !important;
}

#detail-view .data-view table [data-column="actions"] a.filter-columns.has-columns-hidden i {
  color: #dc5e15;
}

#detail-view .data-view table [data-column="actions"] div#filter-columns-list.open {
  width: auto;
}

#detail-view .data-view table [data-column="actions"] div#filter-columns-list.open h2 {
  padding: 0.5rem;
  background: #c5d1e2;
  border-bottom: 1px solid lightgray;
}

#detail-view .data-view table [data-column="actions"] div#filter-columns-list.open > div {
  padding: 0 0.5rem;
}

#detail-view.condensed .data-view table td[data-column="appId"] {
  width: 5rem;
  max-width: 5rem;
}

#detail-view.condensed .data-view table [data-column="a.lastName"] {
  text-align: left;
}

#detail-view div.table-wrapper {
  overflow-x: auto;
}
