.bulletchart-canvas {
  display: inline-block;
  width: inherit;
  height: inherit;
}

.bulletchart {
  border-radius: 0.25em;
}

.chart-container {
  position:relative;
  display: inline-block;
  width: 100%;
}

.chart-container > canvas {
  /* flex-grow: 1; */
  display: block;
}

.chart-container > a.action-icon {
  float: right;
}

#slider {
  margin-bottom: 1rem;
  background: #ccc;
  border-color: #bbb;
}

.ui-slider-handle.ui-state-default {
  color: transparent;
  background: #ddd;
  border-color: #888;
}

.active-handle {
  border: 2px solid #2DB1FF !important;
}
