/* Again, hopefully we can remove this */

.legacy-tooltip {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
}

.legacy-tooltip > span {
  border-bottom: 1px dotted inherit;
}

.legacy-tooltip-content {
  position: absolute;
  top: 100%;
  right: -3rem;
  left: -3rem;
  z-index: -1;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  opacity: 0;
}

.legacy-tooltip:hover {
  cursor: help;
}

.legacy-tooltip:hover .legacy-tooltip-content {
  z-index: 300;
  cursor: auto;
  opacity: 1;
  transition-delay: 250ms;
  transition-duration: 500ms;
  transition-property: opacity;
}

.legacy-tooltip:hover > span {
  border-bottom: 1px solid inherit;
}