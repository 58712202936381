.vimeo-container {
  display: none;
}

.vimeo-controls {
  margin-top: 10px;
}

.vimeo-controls a,
.vimeo-controls a:focus {
  width: 50px;
  background-color: #33b5e5;
}

.vimeo-controls a:hover {
  background-color: lightskyblue;
}

.vimeo-controls a i {
  width: 100%;
}

.vimeo-progress {
  display: inline;
  margin-left: 5px;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 100%;
}

a.button.disabled,
a.button.disabled:hover {
  pointer-events: none;
  background-color: #d4d4d4;
  opacity: 0.5;
}
