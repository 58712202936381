#skillbuilder-dashboard .assessment-complete {
  color: rgb(50 200 150);
}

#skillbuilder-dashboard .assessment-incomplete {
  color: lightgrey;
}

.skillbuilder-dashboard-container {
  padding: 5px;
  margin-bottom: 10px;
  background-color: #fbfbfb;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 0 5px 0 rgb(43 43 43 / 10%);
}

#skillbuilder-list-container {
  overflow-y: hidden;
  font-size: 0.75rem;
  text-align: center;
}

#skillbuilder-list-container div {
  max-height: 200px;
  margin: 0 0 4px;
}

#skillbuilder-list-container .skillbuilder-list-container-header {
  box-shadow: 0 10px 3px -8px rgb(43 43 43 / 10%);
}

#skillbuilder-list-container .skillbuilder-list-container-header div {
  font-weight: bold;
  line-height: 2rem;
}

#skillbuilder-list-container .skillbuilder-list-container-body {
  overflow-y: auto;
}

.skillbuilder-list-container-body div.row:nth-child(even) {
  background-color: #f2f2f2;
}

#skillbuilder-list-container .skillbuilder-list-container-header div.columns:first-child {
  text-align: left;
}

#skillbuilder-list-container .skillbuilder-list-container-body div.columns:first-child {
  text-align: left;
}

#skillbuilder-dashboard .cycle-status {
  float: right;
  max-width: 200px;
}
