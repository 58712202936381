.process-assessment-options {
  margin-bottom: 0.25rem;
}

.process-assessment-handle {
  color: #5d676f;
}

.process-assessment-handle:hover {
  color: #989ea4;
  cursor: grab;
}
