.loaded {
  background-color: white;
}

.applicant-responses-wrapper .survey-table {
  border-collapse: collapse;
}

.applicant-responses-wrapper .survey-table tr:nth-child(even) {
  background: #efefef;
}

.applicant-responses-wrapper .survey-table tr th {
  color: white;
  background: #2DB1FF;
}

.applicant-responses-wrapper main .row {
  margin-left: 0;
}
